import './index.scss'

$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".nav li").hover(function(){
    $(this).find(".navchild").css("display","flex");
},function(){
    $(this).find(".navchild").css("display","none");
})
$(".nav li:nth-of-type(3)").hover(function(){
    $(".navchild",this).addClass("active2");
    $(this).parents(".head").siblings(".bg1").addClass("active3");
},function(){
    $(".navchild",this).removeClass("active2");
    $(this).parents(".head").siblings(".bg1").removeClass("active3");
});
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})
$(".search").click(function(){
    $("header .head").hide();
    $(".search-box").css("display","flex");
})
$(".search-out").click(function(){
    $("header .head").show();
    $(".search-box").hide();
})

$("header").addClass("head1");
// $("header .navchild a").click(function () {
//     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
//           var $target = $(this.hash);
//           $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
//           if ($target.length) {
//               var targetOffset = $target.offset().top;
//               $('html,body').animate({
//                   scrollTop: targetOffset - 98
//               },500);
//               return false;
//           }
//       }
// })
$(".navchild .nav1 a").eq(0).addClass("active");
$(".navchild .pic img").eq(0).addClass("active");
$(".navchild .nav-list .content").eq(0).addClass("active");
$(".navchild .nav1 a").hover(function(){
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".navchild .pic img").eq(index).addClass("active").siblings().removeClass("active");
    $(".navchild .nav-list .content").eq(index).addClass("active").siblings().removeClass("active");
})